import axios from "axios";
import { baseUrl, headers } from "../config/env";

export function fetchCultureInfoList(params) {
  return axios.get(baseUrl + `/cultureInfo/list`, { params, headers });
}
export function fetchDbyhsByNcpms(params) {
  return axios.get(baseUrl + `/cultureInfo/ncpms`, { params, headers });
}
export function fetchDbyhsDetails(params) {
  return axios.get(baseUrl + `/cultureInfo/ncpmsdetail`, { params, headers });
}
export function fetchCultureInfoDetails(id) {
  return axios.get(baseUrl + `/cultureInfo/${id}`, { headers });
}

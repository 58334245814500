<template>
  <div class="container withTop">
    <section class="contents cultureinfo detail">
      <article class="box" v-if="serviceCode == 'SVC05' && detail != undefined">
        <h3>
          <span>{{ detail.sickNameKor._text }}</span
          >이란?
        </h3>
        <div v-if="detail.imageList.item.length > 0">
          <img v-for="(data, i) in detail.imageList.item" :key="i" :src="`https://${data.image._text.substr(7)}`" :alt="`${data.imageTitle._text}`" />
        </div>
        <img v-else :src="`https://${detail.imageList.item.image._text.substr(7)}`" :alt="`${detail.imageList.item.imageTitle._text}`" />

        <h4>발생환경</h4>
        <p v-html="detail.developmentCondition._text"></p>
        <h4>증상설명</h4>
        <p v-html="detail.symptoms._text"></p>
        <h4>방제방법</h4>
        <p v-html="detail.preventionMethod._text"></p>
      </article>
      <article class="box" v-if="serviceCode == 'SVC07' && detail != undefined">
        <h3>
          <span>{{ detail.insectSpeciesKor._text }}</span
          >이란?
        </h3>
        <div v-if="detail.imageList.item.length > 0">
          <img v-for="(data, i) in detail.imageList.item" :key="i" :src="`https://${data.image._text.substr(7)}`" :alt="`${data.imageTitle._text}`" />
        </div>
        <img v-else :src="`https://${detail.imageList.item.image._text.substr(7)}`" :alt="`${detail.imageList.item.imageTitle._text}`" />
        <h4>형태정보</h4>
        <p v-html="detail.stleInfo._text"></p>
        <h4>생태정보</h4>
        <p v-html="detail.ecologyInfo._text"></p>
        <h4>피해정보</h4>
        <p v-html="detail.damageInfo._text"></p>

        <h4 v-if="detail.preventMethod._text">방제방법</h4>
        <p v-html="detail.preventMethod._text"></p>
        <h4 v-if="detail.biologyPrvnbeMth._text">생물학적 방제방법</h4>
        <p v-html="detail.biologyPrvnbeMth._text"></p>
        <h4 v-if="detail.chemicalPrvnbeMth._text">화학적 방제방법</h4>
        <p v-html="detail.chemicalPrvnbeMth._text"></p>
      </article>
      <article class="box" v-if="id && detail != undefined">
        <h3>
          <span>{{ detail.title }}</span>
        </h3>
        <p v-html="detail.content_1"></p>
        <p v-html="detail.content_2"></p>
        <p v-html="detail.content_3"></p>
        <div v-if="detail.images.length > 0">
          <img v-for="(data, i) in detail.images" :key="i" :src="`${data}`" />
        </div>
      </article>
    </section>
  </div>
</template>
<script>
import { fetchDbyhsDetails, fetchCultureInfoDetails } from "@/api/cultureinfo";

export default {
  data() {
    return {
      id: "",
      key: "",
      serviceCode: "",
      detail: undefined,
    };
  },

  created() {
    this.$store.dispatch("SET_NAVBAR", 2);
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", true);
    this.$store.dispatch("SET_PAGE_NAME", "병해충 상세정보");
  },
  mounted() {
    if (this.$route.query.key) {
      this.key = this.$route.query.key;
      this.serviceCode = this.$route.query.serviceCode;
      this.getDbyhsDetails();
    } else {
      this.id = this.$route.query.id;
      this.getCultureInfoDetails();
    }
  },
  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    getCultureInfoDetails() {
      fetchCultureInfoDetails(this.id).then((res) => {
        if (res.status == 200) {
          this.detail = res.data.data;
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
    getDbyhsDetails() {
      let params = {
        key: this.key,
        serviceCode: this.serviceCode,
      };
      fetchDbyhsDetails(params).then((res) => {
        if (res.status == 200) {
          this.detail = res.data.data.service;
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
  },
};
</script>
